<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="6" sm="3">
          <h5 id="title_head">初期費用合計(税別)</h5>
          <p class="text-right mb-0 sum_border">
            <span class="text-h5">
              {{ total_initial_fee.toLocaleString() }}
            </span>
            円
          </p>
        </v-col>
        <v-col cols="6" sm="3">
          <h5 id="title_head">月額合計(税別)</h5>
          <p class="text-right mb-0 sum_border">
            <span class="text-h5">
              {{ total_fixed_fee.toLocaleString() }}
            </span>
            円
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="（キーワード検索）"
            single-line
            hide-details
            clearable
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      item-key="id"
      :mobile-breakpoint="0"
      :items-per-page="100"
      :footer-props="{
        'items-per-page-options': [100, 200, 400, -1],
        'items-per-page-text': '表示件数',
      }"
    >
      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
          props.itemsLength
        }}件
      </template>
      <template v-slot:item.shop_name="{ item }">
        <div :class="item.middle_data ? 'text-red' : ''">
          {{ item.shop_name }}
        </div>
      </template>
      <template v-slot:item.sc_date="{ item }">
        <div :class="item.middle_data ? 'text-red' : ''">
          {{ item.sc_date }}
        </div>
      </template>
      <template v-slot:item.scd_plan_name="{ item }">
        <span
          :class="
            item.scd_plan_code.slice(0, 2) == setting.plan_type_id_basic
              ? 'plan_name_primary'
              : 'plan_name_secondary'
          "
        >
          {{ item.scd_plan_name }}
        </span>
      </template>
      <template v-slot:item.contract="{ item }">
        <v-icon class="pa-1" @click="contractItem(item)">
          mdi-note-edit-outline
        </v-icon>
      </template>
      <template v-slot:item.scd_initial_fee="{ item }">
        <div class="right-input">
          {{ item.scd_initial_fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.scd_fixed_fee="{ item }">
        <div class="right-input">
          {{ item.scd_fixed_fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.scd_quantity="{ item }">
        <div class="right-input">
          {{ item.scd_quantity.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.sc_total_fee="{ item }">
        <div class="right-input">
          {{ item.sc_total_fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:no-data> 表示するデータがありません </template>
    </v-data-table>
  </div>
</template>

<script>
import { reactive, toRefs, watch, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import router from "@/router/index";
import utilDate from "@/common/utilDate";

export default defineComponent({
  props: {
    sales_month: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    const shopContractsRepository = repositoryFactory.get("shopContracts");
    const state = reactive({
      total_initial_fee: 0,
      total_fixed_fee: 0,
      search: "",
      headers: [
        {
          text: "登録ユーザー名",
          value: "user_name",
          sortable: true,
          class: "td_user_name",
        },
        {
          text: "店舗名",
          value: "shop_name",
          sortable: true,
          class: "td_shop_name",
        },
        {
          text: "プラン",
          value: "scd_plan_name",
          sortable: true,
          class: "td_plan_name",
        },
        {
          text: "期間",
          value: "sc_date",
          sortable: true,
          class: "td_date",
        },
        {
          text: "契約",
          value: "contract",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "初期費用",
          value: "scd_initial_fee",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "月額",
          value: "scd_fixed_fee",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "個数",
          value: "scd_quantity",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "合計",
          value: "sc_total_fee",
          sortable: true,
          class: "td_fee",
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    const getStartAndEndDates = (ym) => {
      // 年と月を分割
      const [year, month] = ym.split("-").map(Number);

      // その月の最初の日を取得
      const firstDay = new Date(year, month - 1, 1);

      // 次の月の最初の日を取得し、1日減算してその月の最後の日を取得
      const lastDay = new Date(year, month, 0);

      return {
        firstDay: utilDate.getDateFormatLong(firstDay),
        lastDay: utilDate.getDateFormatLong(lastDay),
      };
    };

    const getList = async (month) => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      state.total_initial_fee = 0;
      state.total_fixed_fee = 0;

      if (!month) return;

      const params = {
        sales_month: month.replace("-", ""),
      };

      const check_month = getStartAndEndDates(month);

      state.desserts.splice(0);
      await shopContractsRepository
        .admin_sales(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const row = response.data[key];
              // 月額合計を計算
              row["sc_total_fee"] = row["scd_fixed_fee"] * row["scd_quantity"];
              // 月途中で契約が開始又は終了しているか
              row["middle_data"] = false;
              if (
                (row["sc_start_date"] > check_month.firstDay &&
                  row["sc_start_date"] <= check_month.lastDay) ||
                (row["sc_end_date"] >= check_month.firstDay &&
                  row["sc_end_date"] < check_month.lastDay)
              ) {
                row["middle_data"] = true;
              }
              if (row["sc_end_date"] == setting.auto_flag_end_date) {
                row["sc_end_date"] = "自動更新";
              }
              row["sc_date"] =
                row["sc_start_date"] + "\n～\n" + row["sc_end_date"];
              state.desserts.push(row);
              state.total_initial_fee += row.scd_initial_fee;
              state.total_fixed_fee += row.sc_total_fee;
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/index.vue/deleteItem shopsRepository.admin_list (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    // キャストID監視（0の時、ダイアログを閉じる。0以外の時、ダイアログを開く。）
    watch(
      () => props.sales_month,
      async (newValue, oldValue) => {
        await getList(newValue);
      }
    );

    const contractItem = (item) => {
      router.push({
        name: "admin-contract-edit",
        params: {
          shop_id: item.shop_id,
        },
      });
    };

    const init = async () => {
      await getList(props.sales_month);
    };

    init();

    return {
      setting,
      ...toRefs(state),
      contractItem,
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_user_name {
  min-width: 100px;
  width: 150px;
}
::v-deep .td_shop_name {
  min-width: 150px;
  width: 200px;
}
::v-deep .td_plan_name {
  min-width: 100px;
  width: 150px;
}
::v-deep .td_fee {
  min-width: 60px;
  width: 80px;
}
::v-deep .td_date {
  min-width: 120px;
  width: 160px;
}
.plan_name_primary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #1976d2;
}
.plan_name_secondary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #5cbbf6;
}
.sum_border {
  border-bottom: 1px solid #000000;
}
</style>
