<template>
  <div>
    <v-card elevation="0">
      <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
        <span class="text-red">「赤色」</span
        >で表示されているデータは、契約期間が月途中で開始又は終了しているデータです。金額に間違いがないか確認してください。
      </div>
      <v-card-title>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" sm="4" md="2">
              <v-menu v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="text"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    label="年月"
                    style="max-width: 275px"
                    hide-details
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="picker"
                  type="month"
                  locale="jp-ja"
                  @input="formatDate(picker)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <v-tabs v-model="tabItems" class="mb-3">
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
          tab.name
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabItems" touchless>
        <v-tab-item v-for="tab in tabs" :key="tab.index">
          <component
            v-bind:is="tab.component"
            v-bind:sales_month="picker"
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  set,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate";
import router from "@/router/index";
import ContractComponent from "./component/contract.vue";
import UserComponent from "./component/user.vue";

export default defineComponent({
  components: {
    ContractComponent,
    UserComponent,
  },
  setup(_, ctx) {
    const tabItems = ref();
    const tabs = [
      {
        index: 1,
        name: "契約一覧",
        component: ContractComponent,
      },
      {
        index: 2,
        name: "ユーザー・契約別合計",
        component: UserComponent,
      },
    ];
    const state = reactive({
      menu: false,
      text: null,
      picker: null,
    });

    // 年月入力部品
    const formatDate = async (date) => {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      state.text = `${year}年${month}月`;
      state.menu = false;
      return;
    };

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 今月を入れる
      const today = new Date();
      state.picker = utilDate.getMonthFormatLong(today);
      await formatDate(state.picker);

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    const contractItem = (item) => {
      router.push({
        name: "admin-contract-edit",
        params: {
          shop_id: item.shop_id,
        },
      });
    };

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      init,
      formatDate,
      contractItem,
      sales_tab: "contract",
      tabItems,
      tabs,
    };
  },
});
</script>
