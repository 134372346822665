<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="6" sm="3">
          <general-button btn_type="info" btn_block @click-event="onExport"
            ><template v-slot:icon><v-icon left>mdi-download</v-icon></template
            >Excel出力</general-button
          >
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col cols="6" sm="3">
          <h5 id="title_head">初期費用合計(税別)</h5>
          <p class="text-right mb-0 sum_border">
            <span class="text-h5">
              {{ total_initial_fee.toLocaleString() }}
            </span>
            円
          </p>
        </v-col>
        <v-col cols="6" sm="3">
          <h5 id="title_head">月額合計(税別)</h5>
          <p class="text-right mb-0 sum_border">
            <span class="text-h5">
              {{ total_fixed_fee.toLocaleString() }}
            </span>
            円
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="（キーワード検索）"
            single-line
            hide-details
            clearable
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      item-key="id"
      :mobile-breakpoint="0"
      :items-per-page="100"
      :footer-props="{
        'items-per-page-options': [100, 200, 400, -1],
        'items-per-page-text': '表示件数',
      }"
    >
      <template v-slot:footer.page-text="props">
        {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
          props.itemsLength
        }}件
      </template>
      <template v-slot:item.shop_names="{ item }">
        <div :class="item.row_type == 'sum' ? ` sum` : ``">
          {{ item.shop_names }}
        </div>
      </template>
      <template v-slot:item.scd_plan_name="{ item }">
        <span
          v-if="item.scd_plan_name.length > 0"
          :class="
            item.scd_plan_code.slice(0, 2) == setting.plan_type_id_basic
              ? 'plan_name_primary'
              : 'plan_name_secondary'
          "
        >
          {{ item.scd_plan_name }}
        </span>
        <div v-else :class="item.row_type == 'sum' ? ` sum` : ``">
          {{
            (item.sc_total_initial_fee + item.sc_total_fee).toLocaleString()
          }}円 (税別)
        </div>
      </template>
      <template v-slot:item.sc_total_initial_fee="{ item }">
        <div :class="`right-input` + (item.row_type == 'sum' ? ` sum` : ``)">
          {{ item.sc_total_initial_fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.scd_fixed_fee="{ item }">
        <div class="right-input">
          {{ item.scd_fixed_fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.scd_quantity="{ item }">
        <div class="right-input">
          {{ item.scd_quantity.toLocaleString() }}
        </div>
      </template>
      <template v-slot:item.sc_total_fee="{ item }">
        <div :class="`right-input` + (item.row_type == 'sum' ? ` sum` : ``)">
          {{ item.sc_total_fee.toLocaleString() }}
        </div>
      </template>
      <template v-slot:no-data> 表示するデータがありません </template>
    </v-data-table>
  </div>
</template>

<script>
import { reactive, toRefs, watch, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import router from "@/router/index";
import utilDate from "@/common/utilDate";
import ExcelJS from "exceljs";

export default defineComponent({
  props: {
    sales_month: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    const shopContractsRepository = repositoryFactory.get("shopContracts");
    const state = reactive({
      total_initial_fee: 0,
      total_fixed_fee: 0,
      search: "",
      headers: [
        {
          text: "登録ユーザー名",
          value: "user_name",
          sortable: true,
          class: "td_user_name",
        },
        {
          text: "店舗名",
          value: "shop_names",
          sortable: true,
          class: "td_shop_names",
        },
        {
          text: "プラン",
          value: "scd_plan_name",
          sortable: true,
          class: "td_plan_name",
        },
        {
          text: "初期費用",
          value: "sc_total_initial_fee",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "月額",
          value: "scd_fixed_fee",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "個数",
          value: "scd_quantity",
          sortable: true,
          class: "td_fee",
        },
        {
          text: "合計",
          value: "sc_total_fee",
          sortable: true,
          class: "td_fee",
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    const getStartAndEndDates = (ym) => {
      // 年と月を分割
      const [year, month] = ym.split("-").map(Number);

      // その月の最初の日を取得
      const firstDay = new Date(year, month - 1, 1);

      // 次の月の最初の日を取得し、1日減算してその月の最後の日を取得
      const lastDay = new Date(year, month, 0);

      return {
        firstDay: utilDate.getDateFormatLong(firstDay),
        lastDay: utilDate.getDateFormatLong(lastDay),
      };
    };

    const getList = async (month) => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      state.total_initial_fee = 0;
      state.total_fixed_fee = 0;

      if (!month) return;

      const params = {
        sales_month: month.replace("-", ""),
      };

      state.desserts.splice(0);
      await shopContractsRepository
        .admin_sales_user(params)
        .then((response) => {
          if (response.data) {
            let keep_user_id = 0;
            let keep_user_name = "";
            let user_total_initial_fee = 0;
            let user_total_fixed_fee = 0;

            for (let i = 0; i < response.data.length; i++) {
              const row = response.data[i];
              if (keep_user_id != row.user_id) {
                if (keep_user_id != 0) {
                  // 前のユーザーの月額合計を格納
                  state.desserts.push({
                    row_type: "sum",
                    user_name: "",
                    shop_names: keep_user_name + "合計金額",
                    scd_plan_name: "",
                    sc_total_initial_fee: user_total_initial_fee,
                    scd_fixed_fee: "",
                    scd_quantity: "",
                    sc_total_fee: user_total_fixed_fee,
                  });
                }
                keep_user_id = row.user_id;
                keep_user_name = row.user_name;
                user_total_initial_fee = 0;
                user_total_fixed_fee = 0;
              }
              // 月額合計を計算
              row["sc_total_initial_fee"] =
                row["scd_initial_fee"] * row["scd_quantity"];
              row["sc_total_fee"] = row["scd_fixed_fee"] * row["scd_quantity"];
              row["row_type"] = "data";
              state.desserts.push(row);
              state.total_initial_fee += row.sc_total_initial_fee;
              state.total_fixed_fee += row.sc_total_fee;
              user_total_initial_fee += row.sc_total_initial_fee;
              user_total_fixed_fee += row.sc_total_fee;
            }
            if (keep_user_id != 0) {
              // 前のユーザーの月額合計を格納
              state.desserts.push({
                row_type: "sum",
                user_name: "",
                shop_names: keep_user_name + "合計金額",
                scd_plan_name: "",
                sc_total_initial_fee: user_total_initial_fee,
                scd_fixed_fee: "",
                scd_quantity: "",
                sc_total_fee: user_total_fixed_fee,
              });
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/index.vue/deleteItem shopsRepository.admin_list (" +
            error +
            ")"
          );
        });

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    // キャストID監視（0の時、ダイアログを閉じる。0以外の時、ダイアログを開く。）
    watch(
      () => props.sales_month,
      async (newValue, oldValue) => {
        await getList(newValue);
      }
    );

    const contractItem = (item) => {
      router.push({
        name: "admin-contract-edit",
        params: {
          shop_id: item.shop_id,
        },
      });
    };

    const onExport = async () => {
      if (state.desserts.length == 0) {
        alert("出力するデータがありません。");
        return;
      }

      // 現在日時をUNIX時間で取得
      const now = new Date();
      const now_unix = Math.floor(now.getTime() / 1000);
      const output_file = `sales_user_${now_unix}.xlsx`;

      // Excelファイル作成
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet("ユーザー別合計一覧");
      const worksheet = workbook.getWorksheet("ユーザー別合計一覧");

      // ヘッダー行作成
      worksheet.columns = [
        { header: "年月", key: "year_month", width: 15 },
        { header: "ユーザー名", key: "user_name", width: 15 },
        { header: "店舗名", key: "shop_names", width: 30 },
        { header: "プラン", key: "scd_plan_name", width: 15 },
        { header: "初期費用", key: "sc_total_initial_fee", width: 12 },
        { header: "単価", key: "scd_fixed_fee", width: 12 },
        { header: "個数", key: "scd_quantity", width: 12 },
        { header: "月額合計", key: "sc_total_fee", width: 12 },
      ];

      // データ行作成
      state.desserts.forEach((row) => {
        const row_data = row;
        row_data["year_month"] = props.sales_month.replace("-", "年") + "月";
        worksheet.addRow(row_data);
      });

      // エクセルを保存
      const blob = new Blob([await workbook.xlsx.writeBuffer()], {
        type: "application/octet-binary",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = output_file;
      a.click();
      a.remove();
    };

    const init = async () => {
      await getList(props.sales_month);
    };

    init();

    return {
      setting,
      ...toRefs(state),
      contractItem,
      onExport,
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_user_name {
  min-width: 100px;
  width: 150px;
}
::v-deep .td_shop_names {
  min-width: 200px;
  width: 400px;
}
::v-deep .td_plan_name {
  min-width: 100px;
  width: 150px;
}
::v-deep .td_fee {
  min-width: 60px;
  width: 80px;
}
::v-deep .td_date {
  min-width: 120px;
  width: 160px;
}
.plan_name_primary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #1976d2;
}
.plan_name_secondary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #5cbbf6;
}
.sum_border {
  border-bottom: 1px solid #000000;
}
.sum {
  font-weight: bold;
  font-size: 1.25em;
}
</style>
