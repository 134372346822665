var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('general-button',{attrs:{"btn_type":"info","btn_block":""},on:{"click-event":_vm.onExport},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")])]},proxy:true}])},[_vm._v("Excel出力")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('h5',{attrs:{"id":"title_head"}},[_vm._v("初期費用合計(税別)")]),_c('p',{staticClass:"text-right mb-0 sum_border"},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.total_initial_fee.toLocaleString())+" ")]),_vm._v(" 円 ")])]),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('h5',{attrs:{"id":"title_head"}},[_vm._v("月額合計(税別)")]),_c('p',{staticClass:"text-right mb-0 sum_border"},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.total_fixed_fee.toLocaleString())+" ")]),_vm._v(" 円 ")])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"（キーワード検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"item-key":"id","mobile-breakpoint":0,"items-per-page":100,"footer-props":{
      'items-per-page-options': [100, 200, 400, -1],
      'items-per-page-text': '表示件数',
    }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.shop_names",fn:function(ref){
    var item = ref.item;
return [_c('div',{class:item.row_type == 'sum' ? " sum" : ""},[_vm._v(" "+_vm._s(item.shop_names)+" ")])]}},{key:"item.scd_plan_name",fn:function(ref){
    var item = ref.item;
return [(item.scd_plan_name.length > 0)?_c('span',{class:item.scd_plan_code.slice(0, 2) == _vm.setting.plan_type_id_basic
            ? 'plan_name_primary'
            : 'plan_name_secondary'},[_vm._v(" "+_vm._s(item.scd_plan_name)+" ")]):_c('div',{class:item.row_type == 'sum' ? " sum" : ""},[_vm._v(" "+_vm._s((item.sc_total_initial_fee + item.sc_total_fee).toLocaleString())+"円 (税別) ")])]}},{key:"item.sc_total_initial_fee",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:"right-input" + (item.row_type == 'sum' ? " sum" : "")},[_vm._v(" "+_vm._s(item.sc_total_initial_fee.toLocaleString())+" ")])]}},{key:"item.scd_fixed_fee",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"right-input"},[_vm._v(" "+_vm._s(item.scd_fixed_fee.toLocaleString())+" ")])]}},{key:"item.scd_quantity",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"right-input"},[_vm._v(" "+_vm._s(item.scd_quantity.toLocaleString())+" ")])]}},{key:"item.sc_total_fee",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:"right-input" + (item.row_type == 'sum' ? " sum" : "")},[_vm._v(" "+_vm._s(item.sc_total_fee.toLocaleString())+" ")])]}},{key:"no-data",fn:function(){return [_vm._v(" 表示するデータがありません ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }